import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { getSelectedSymbol } from 'entities/new-terminal/model/selectors/get-selected-symbol';
import { getChartLastPrice } from 'entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price';
import { useFormContext } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Switch, Text } from 'shared/ui';
import { useTradingForm } from '../../hooks/use-trading-form';
import { Buy } from '../buy/ui/buy';
import { Sell } from '../sell';
import { segments } from './consts';
import { inner, text, wrapper } from './styles';

interface TradingPanelProps {
  currentSymbol?: any;
  userWalletData: any;
  userWalletBaseAsset: any;
  exchangeAccountUuid: string;
}

const TradingContent = ({
  currentSymbol,
  userWalletData,
  userWalletBaseAsset,
  exchangeAccountUuid,
}: TradingPanelProps) => {
  const {
    watch, 
  } = useFormContext();
  const selectedSide = watch('selectedSide');
  
  if (selectedSide === 'buy') {
    return (
      <Buy 
        currentSymbol={currentSymbol} 
        userWallet={userWalletData} 
        userWalletBaseAsset={userWalletBaseAsset} 
        exchangeAccountUuid={exchangeAccountUuid}
      />
    );
  }

  return (
    <Sell
      currentSymbol={currentSymbol} 
      userWallet={userWalletData}
      userWalletBaseAsset={userWalletBaseAsset}
      exchangeAccountUuid={exchangeAccountUuid}
    />
  );
};

export const TradingPanel = (props: TradingPanelProps) => {
  const {
    userWalletData, ...rest 
  } = props;

  const chartLastPrice = useSelector(getChartLastPrice);
  const currentSymbol = useSelector(getSelectedSymbol).selectedSymbol;
  
  const methods = useTradingForm(
    chartLastPrice, 
    currentSymbol, 
    userWalletData,
  );
  
  const {
    setValue, watch, 
  } = methods;
  
  const selectedSide = watch('selectedSide');
  const isSkipBaseOrder = watch('skipBaseOrder');
  
  const changeSegmentHandler = (value: string) => {
    setValue('selectedSide', value);
  };

  const skipBaseOrderHandler = (value: boolean) => {
    setValue('skipBaseOrder', value);
  };

  return (
    <FormProvider {...methods}>
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Segmented
            value={selectedSide}
            onChange={changeSegmentHandler}
            options={segments}
            block={true}
          />
        
          <Switch
            value={isSkipBaseOrder}
            onChange={skipBaseOrderHandler}
            suffixText={(
              <Text type='secondary' styles={text}>
                Skip base order (use existing funds)
              </Text>
            )}
            size='small'
          />
        </Box>

        <TradingContent 
          currentSymbol={currentSymbol}
          userWalletData={userWalletData}
          {...rest}
        />
      </Box>
    </FormProvider>
  );
};
