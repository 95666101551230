import { Box, Hidden, Stack } from '@mui/material';
import { formatReturnPercent, getFormattedAndTrimmedReturnValue, isReturnEmpty, showActionButtons } from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { formatDate, formatTime } from 'pages/manual-trading/trading-terminal/helpers/trade-date-formatter';
import { capitalizeFirstLetter } from 'pages/trading-bots/trading-history/helpers/get-capitalized-first-letter';
import { Fragment, useState } from 'react';
import { exchangeIcons, IExchangeIcons } from 'shared/consts/exchange-icons';
import { useModalContent } from 'shared/hooks/use-modal-content';
import { useModalState } from 'shared/hooks/use-modal-state';
import { Loader, Text } from 'shared/ui';
import { Modal } from 'shared/ui/modal/ui/modal';
import { useMenuItems } from '../../../hooks/use-menu-items';
import { useTradeActions } from '../../../hooks/use-trade-actions';
import { BriefTradeData } from '../../brief-trade-data/brief-trade-data';
import { TradeProgress } from '../../trade-progress/trade-progress';
import {
  text,
  wrapper,
} from '../styles';
import { ActiveTradeProps } from '../types/active-trade.types';
import { TradeActions } from './trade-actions';

export const ActiveTrade = ({
  trade,
  tradeSymbol,
  chartLastPrice,
  exchangeAccount,
}: ActiveTradeProps) => {

  const {
    modalState, openModal, closeModal, 
  } = useModalState();

  const [isLoading, setIsLoading] = useState(false);
    
  const showActionResult = (status: 'success' | 'error', message: string) => {
    openModal({
      type: 'actionResult',
      status,
      message,
    });
  };
  
  const tradeActions = useTradeActions({
    tradeUuid: trade.smartTradeUuid,
    closeModal,
    showActionResult,
    setIsLoading,
  });
  
  const menuItems = useMenuItems({
    trade,
    openModal,
    ...tradeActions,
  });
  
  const {
    renderModalContent, 
  } = useModalContent({
    modalState,
    chartLastPrice,
    onClose: closeModal,
  });
    
  return (
    <Fragment>  
      <Modal
      // @TODO перенести компонент modal ant внутрь этой самописной модалки
        isOpen={modalState.isOpen}
        onClose={closeModal}
        width={modalState.type === 'actionResult' ? 300 : 400}
      >
        {renderModalContent()}
      </Modal>
      
      <Hidden mdDown={true}>
        <Box sx={wrapper}>
          {isLoading && <Loader isContentOverflow={true} />}

          <BriefTradeData
            trade={trade}
            tradeSymbol={tradeSymbol}
            menuItems={menuItems} 
            onUpdate={tradeActions.handleUpdateTrade} 
          />
        </Box>
      </Hidden>

      <Hidden mdUp={true}>
        <Stack gap='24px'>
          <Stack gap='14px'>
            <Stack
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              gap={{
                xs: '16px',
                sm: '34px',
              }}
            >
              <Stack
                flexDirection='row'
                alignItems='center'
                gap={{
                  xs: '20px',
                  sm: '40px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    '&>span': {
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: '17px',
                    },
                  }}
                >
                  <Text>
                    {trade?.baseSymbol}
                  </Text>

                  <Text type='success'>
                    {trade?.quoteSymbol}
                  </Text>
                </Box>

                <Box
                  sx={{
                    padding: '5px',
                    backgroundColor: '#F2F2F2',
                    borderRadius: '2.55px',
                  }}
                >
                  <Text
                    type='secondary'
                    styles={text}
                  >
                    {capitalizeFirstLetter(trade.status)}
                  </Text>
                </Box>
              </Stack>

              {showActionButtons(trade) && (
                <TradeActions
                  menuItems={menuItems} 
                  onUpdate={tradeActions.handleUpdateTrade} 
                />
              )}
            </Stack>

            <Stack
              flexDirection='row'
              gap='4px'
              alignItems='center'
            >
              <Box display='flex'>
                {exchangeIcons[(exchangeAccount?.exchangeCode) as keyof IExchangeIcons]}
              </Box>

              <Text>
                {exchangeAccount?.label}
              </Text>
            </Stack>
          </Stack>

          <Stack
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            gap='16px'
          >
            <Stack
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              gap='16px'
            >
              <Stack gap='8px'>
                <Text>
                  {formatDate(trade.createdDate)}
                </Text>

                <Text>
                  {formatTime(trade.createdDate)}
                </Text>
              </Stack>

              <Stack gap='8px'>
                <Text>
                  {formatByPrecisionAndTrim(trade?.executedBaseFirst, tradeSymbol?.baseAssetPrecision, Number(tradeSymbol?.priceMin), Number(tradeSymbol?.priceMax))} {trade?.baseSymbol}
                </Text>

                <Text>
                  {formatByPrecisionAndTrim(trade?.executedBaseFirst, tradeSymbol?.quoteAssetPrecision, Number(tradeSymbol?.priceMin), Number(tradeSymbol?.priceMax))} {trade?.baseSymbol}
                </Text>
              </Stack>
            </Stack>

            {!isReturnEmpty(trade) && (
              <Stack gap='8px'>
                <Text>
                  {getFormattedAndTrimmedReturnValue(trade, tradeSymbol)}
                </Text>

                <Text>
                  {`${formatReturnPercent(trade?.returnPercent)}%`}
                </Text>
              </Stack>
            )}
          </Stack>

          <Stack>
            <TradeProgress
              trade={trade}
              tradeSymbol={tradeSymbol}
            />
          </Stack>
        </Stack>
      </Hidden>
    </Fragment>
  );
};
