import { createAsyncThunk } from '@reduxjs/toolkit';
import { calculateMarketOptions, getFilteredTradingPairs, setDefaultTradingPairAndSymbol } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getUniqueSymbolCodes } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getExchanges } from 'pages/trading-bots/configurator/api/get-exchanges';
import { getTerminalSnapshotsData } from 'pages/trading-terminal-page/order-panel/ExchangeForm/helpers/get-snapshots-terminal-page';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

export const updateExchangeData = createAsyncThunk(
  'trading/updateExchangeData',
  async ({ 
    uuid, 
    exchangeCode, 
    previousMarket,
    selectedExchange,
  }: { 
      uuid: string; 
      exchangeCode: string;
      previousMarket?: any;
      selectedExchange?: any;
    }, {
    rejectWithValue, 
  }) => {
    try {
      const skyrexUserUuid = getSkyrexUuid();
        
      const exchangeSymbols = await getExchangeSymbols(exchangeCode);
      const uniqueSymbolCodes = getUniqueSymbolCodes(exchangeSymbols);
  
      const financeData = await getExchanges([uuid]);
        
      const chosenExchange = financeData?.data?.accounts[0];
      
      const userWalletBalances = chosenExchange.balances;
      
      const marketOptions = calculateMarketOptions(
        chosenExchange.balances,
        uniqueSymbolCodes,
      );
  
      const defaultMarket = previousMarket ? 
        marketOptions.find((option: any) => option.label === previousMarket.label) :
        marketOptions.find((el: any) => el.label === 'USDT') || 
          marketOptions[0];
  
      const tradingPairs = getFilteredTradingPairs(
        exchangeSymbols,
        defaultMarket,
        chosenExchange.balances,
      );
  
      const {
        defaultTradingPair, currentSymbol, 
      } = setDefaultTradingPairAndSymbol(
        tradingPairs,
        exchangeSymbols,
      );
      
      
      const userWalletQuoteAssetBalance = userWalletBalances.find((el: any) => el.symbol === currentSymbol?.quoteAsset);
      const userWalletBaseAssetBalance = userWalletBalances.find((el: any) => el.symbol === currentSymbol?.baseAsset);
      
      //TODO зачем 3 параметра? оптимизировать до 2х
      const dailyChange = await getTerminalSnapshotsData(chosenExchange, skyrexUserUuid ?? '', chosenExchange.exchangeAccountUuid);

      return {
        selectedExchange: selectedExchange,
        financeData,
        dailyChange,
        symbolCodes: uniqueSymbolCodes,
        marketOptions,
        selectedMarket: defaultMarket,
        tradingPairs,
        selectedTradingPair: defaultTradingPair,
        currentSymbol,
        allExchangeSymbols: exchangeSymbols,
        userWalletBalances,
        userWalletQuoteAssetBalance,
        userWalletBaseAssetBalance,
        chosenExchange,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
