//@ts-nocheck
import { Box } from '@mui/material';
//@ts-ignore
import { TVChartContainer } from '../../../../pages/trading-terminal-page/trading-chart/TVChartContainer/index.jsx';
import { ActiveTrades as ActiveTradesComponent } from '../components';
import { ExchangeSelect } from '../components/exchange-select/ui/exchange-select.tsx';
import { MarketSelect } from '../components/market-select/ui/market-select.tsx';
import { TradingPairSelect } from '../components/trading-pair-select/ui/trading-pair-select.tsx';
import { TradingPanel } from '../components/trading-panel/trading-panel.tsx';
import { WalletTable } from '../components/wallet-table/wallet-table.tsx';
import { useTrading } from '../hooks/use-terminal-page-trading-data.ts';
import { filters, graphicWrapper, tableWrapper, wrapper } from '../styles';

export const TradingTerminal = () => {
  const {
    exchanges,
    selectedExchange,
    currentSymbol,
    dailyChange,
    marketOptions,
    selectedMarket,
    tradingPairs,
    chartSymbol,
    selectedTradingPair,
    userWalletBalances,
    isLoading,
    handleExchangeSelect,
    handleMarketSelect,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    chosenExchange,
    handleTradingPairSelect,
  } = useTrading();

  const getTradingPanel = () => {
    return (
      <TradingPanel
        currentSymbol={currentSymbol}
        userWalletData={userWalletQuoteAssetBalance}
        userWalletBaseAsset={userWalletBaseAssetBalance}
        exchangeAccountUuid={'212'}
      />
    );
  };

  return (
    <Box sx={wrapper}>
      <Box sx={tableWrapper}>
        <Box sx={filters}>
          <ExchangeSelect
            exchanges={exchanges}
            selectedExchange={selectedExchange}
            isLoading={isLoading}
            onSelect={handleExchangeSelect}
          />
          
          <MarketSelect
            marketOptions={marketOptions}
            selectedMarket={selectedMarket}
            isLoading={isLoading}
            onSelect={handleMarketSelect}
          />
          
          <TradingPairSelect
            tradingPairs={tradingPairs}
            selectedTradingPair={selectedTradingPair}
            isLoading={isLoading}
            onSelect={handleTradingPairSelect}
          />
        </Box>

        <WalletTable
          userWalletBalances={userWalletBalances}
          chosenExchange={chosenExchange}
          dailyChange={dailyChange}
        />
      </Box>

      <Box sx={graphicWrapper}>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            gap: 3,
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            position: 'relative',
          }}
        > 
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
              height: '100dvh',
              maxHeight: '661px',
            }}
          >
            <TVChartContainer
              exchangeName={selectedExchange?.exchangeCode}
              tradingPair={selectedTradingPair}
              market={selectedMarket}
              currentSymbol={chartSymbol}
            />
          </Box>
          
          {getTradingPanel()}
        </Box>
      </Box>

      <ActiveTradesComponent exchanges={exchanges} />
    </Box>
  );
};

