import { createAsyncThunk } from '@reduxjs/toolkit';
import { setShortExchangesData } from 'entities/exchange/helpers/set-short-exchanges-data';
import { calculateMarketOptions, getFilteredTradingPairs, setDefaultTradingPairAndSymbol } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getUniqueSymbolCodes } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getTerminalSnapshotsData } from 'pages/trading-terminal-page/order-panel/ExchangeForm/helpers/get-snapshots-terminal-page';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { getExchanges } from 'widgets/exchanges/api/get-exchanges';
import { getDemoAccount } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

export const initializeTradingData = createAsyncThunk('trading/initializeData', async (isDemoMode: boolean, params) => {
  const {
    rejectWithValue, 
  } = params;

  try {
    const skyrexUserUuid = getSkyrexUuid();
      
    // TODO: поменять название метода, 
    //оптимизировать с остальными похожими методами в платформе
    const exchangesResponse = await setShortExchangesData(skyrexUserUuid ?? '');

    const filteredAccounts = getDemoAccount(exchangesResponse, isDemoMode);

    // TODO: add type
    const transformedExchanges = filteredAccounts.map((exchange: any) => ({
      label: exchange.accountName,
      value: exchange.exchangeAccountUuid,
      exchangeCode: exchange.exchangeCode,
    }));
  
    const defaultExchange = transformedExchanges[0];
  
    if (!defaultExchange) {
      throw new Error('No exchanges available');
    }
  
    const exchangeSymbols = await getExchangeSymbols(defaultExchange.exchangeCode);
    const uniqueSymbolCodes = getUniqueSymbolCodes(exchangeSymbols);
      
    // TODO: переименовать метод, оптимизировать с остальными похожими методами в платформе
    const financeData = await getExchanges([defaultExchange.value]);
        
    const chosenExchange = financeData?.data?.accounts[0];
      
    const userWalletBalances = chosenExchange.balances;
      
    const marketOptions = calculateMarketOptions(
      chosenExchange.balances,
      uniqueSymbolCodes,
    );
  
    const defaultMarket = 
    // TODO: add type
          marketOptions.find((el: any) => el.label === 'USDT') || 
          marketOptions[0];
  
    const tradingPairs = getFilteredTradingPairs(
      exchangeSymbols,
      defaultMarket,
      chosenExchange.balances,
    );
        
    const {
      defaultTradingPair, currentSymbol, 
    } = setDefaultTradingPairAndSymbol(
      tradingPairs,
      exchangeSymbols,
    );
    
    const userWalletQuoteAssetBalance = userWalletBalances.find((el: any) => el.symbol === currentSymbol?.quoteAsset);
    const userWalletBaseAssetBalance = userWalletBalances.find((el: any) => el.symbol === currentSymbol?.baseAsset);
      
    //TODO зачем 3 параметра? оптимизировать до 2х
    const dailyChange = await getTerminalSnapshotsData(chosenExchange, skyrexUserUuid ?? '', chosenExchange.exchangeAccountUuid);

    return {
      exchanges: transformedExchanges,
      selectedExchange: defaultExchange,
      financeData,
      dailyChange,
      symbolCodes: uniqueSymbolCodes,
      marketOptions,
      selectedMarket: defaultMarket,
      tradingPairs,
      chartSymbol: currentSymbol,
      selectedTradingPair: defaultTradingPair,
      currentSymbol,
      userWalletBalances,
      allExchangeSymbols: exchangeSymbols,
      userWalletQuoteAssetBalance,
      userWalletBaseAssetBalance,
      chosenExchange,
    };
  } catch (error) {
    console.log(error);
    return rejectWithValue(error.message);
  }
},
);
