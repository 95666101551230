import { SxProps, Theme } from '@mui/material';

export const header: SxProps<Theme> = {
  display: {
    xs: 'none',
    md: 'flex',
  },
  alignItems: 'center',
  gap: 1,
  justifyContent: 'space-between',
  padding: '0px 16px',
  '&>div': {
    width: '100%',
    '&:first-of-type': {
      maxWidth: 64,
      marginLeft: '28px',
    },
    '&:nth-child(2)': {
      maxWidth: 85,
    },
    '&:nth-child(3)': {
      maxWidth: 75,
    },
    '&:nth-child(4)': {
      maxWidth: 60,
    },
    '&:nth-child(5)': {
      maxWidth: 346,
    },
    '&:nth-child(6)': {
      maxWidth: 80,
    },
    '&:nth-child(7)': {
      maxWidth: 95,
    },
    '&:last-of-type': {
      maxWidth: 95,
    },
  },
};
